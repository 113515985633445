import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { StationSelectorService } from './station-selector.service';
import { CodeTableService } from 'src/app/core/code-table.service';
import { SelectItem } from 'primeng/api';
import * as _ from 'lodash';

@Component({
  selector: 'mpt-station-selector',
  templateUrl: './station-selector.component.html',
  styleUrls: ['./station-selector.component.less'],
})
export class StationSelectorComponent implements OnInit, OnChanges {
  // 页面请求电站参数
  @Input() staParams: {
    sta_stationIds?: any;
    sta_provinceIds?: any;
    sta_cityIds?: any;
    sta_countyIds?: any;
    sta_propertyComps?: any;
    sta_stationTypes?: any;
    sta_name?: string;
  } = {};

  @Input() appendToBody: boolean = false;

  // 选择器类型  单选 single  多选  multiple
  @Input() mode = 'single';

  // 选择器 内部 输入框 显示 默认显示
  @Input() isShowInput = true;

  // 选择器 内部 弹窗 显示 默认不显示
  @Input() isShowDialog = false;

  // 选择器 选择框不可操作
  @Input() disabled = false;

  // 选择器 内部 弹窗 关闭
  @Output() showEvent = new EventEmitter<boolean>();

  // 选择器 向外传递已选参数
  @Output() outputValueEvent = new EventEmitter<any>();

  @ViewChild('dt', { static: false }) dataTable: any;

  // 选择器内部 已选电站
  public selectedStation: any = [];

  // 自动选择 下拉选项
  public suggestionsStationList: any[] = [];

  public stationCol: { field: string; header?: string; width?: string }[];

  provinceList = [];
  cityList = [];
  countyList = [];

  countries: any[] = [];
  filteredCountriesMultiple: any[];
  propertyComps: [];
  stationTypesList: SelectItem[];
  propertyCompsList: SelectItem[];
  stationForm: FormGroup;
  pager: any = { page: 0, totalRecords: 0, size: 10 };
  stationList: any;
  loading: boolean;

  public autoCompleteFlag = true;

  public items = [
    {
      label: '全选本页',
      command: () => {
        this.selectPageAll();
      },
    },
    {
      label: '跨页全选',
      command: () => {
        this.selectOtherPageAll();
      },
    },
  ];

  public stationValue: any;

  public showLabelNumber = 0;

  public tableSelectedStations: any[] = [];
  tableHeaderChecked = false;

  constructor(
    private stationselectorservice: StationSelectorService,
    private codetableservice: CodeTableService,
    private formBuilder: FormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.staParams && !changes.staParams.firstChange) {
      this.fillValue();
    }
  }

  ngOnInit() {
    this.fillValue();
    this.initform();
    this.stationCol = [
      { field: 'stationId' },
      { field: 'shortName', header: '电站', width: '15%' },
      { field: 'provinceName', header: '省' },
      { field: 'cityName', header: '市' },
      { field: 'countyName', header: '县' },
      { field: 'stationTypeText', header: '电站类型' },
      { field: 'propertyCompName', header: '业主' },
      { field: 'capacity', header: '装机容量 MW', width: '14%' },
    ];
    this.getRegion('CN', 'province');
    this.codetableservice.getNemsProp('stationType').then((res) => {
      this.stationTypesList = res;
    });
    if (this.isShowDialog == true) {
      this.querySampleStations(true);
    }
  }

  /**
   * 初始化组件 填写表格
   * fillValue
   */
  public fillValue() {
    if (this.mode === 'single') {
      this.selectedStation = [];
      if (this.staParams && this.staParams.sta_stationIds) {
        this.stationselectorservice
          .getStaionInfoByStaionId(this.staParams.sta_stationIds)
          .subscribe((res) => {
            this.selectedStation = res;
          });
      }
    } else if (this.mode == 'multiple') {
      if (this.staParams.sta_stationIds) {
        const station = this.staParams.sta_stationIds.split(',').map((item) => {
          return { stationId: item };
        });
        if (station.length > 1) {
          this.autoCompleteFlag = false;
          this.showLabelNumber = station.length;
          this.selectedStation = station;
          this.tableSelectedStations = _.cloneDeep(this.selectedStation);
        } else {
          this.autoCompleteFlag = true;
          if (this.staParams.sta_stationIds) {
            this.stationselectorservice
              .getStaionInfoByStaionId(this.staParams.sta_stationIds)
              .subscribe((res) => {
                this.selectedStation = [res];
                this.tableSelectedStations = _.cloneDeep(this.selectedStation);
              });
          }
        }
      }
    }
  }

  /**
   * 自动完成查询方法
   * autoCompleteMethod
   */
  public autoCompleteMethod(event: { query: any }) {
    const params = {
      name: event.query,
    };
    this.stationselectorservice
      .getStationAutoComplate(params)
      .then((res: any) => {
        this.suggestionsStationList = res;
      });
  }

  /**
   * suggestionsSelect
   */
  public onSuggestionsSelect(event) {
    if (this.mode === 'single') {
      this.selectedStation = event;
    } else if (this.mode === 'multiple') {
    }
    this.emitSelectData();
  }

  /**
   * onSuggestionsUnselect
   */
  public onSuggestionsUnselect(event) {
    this.selectedStation = [];
    this.emitSelectData();
  }

  public onBlur(event) {
    if (this.mode !== 'single') {
      event.target.value = '';
    }
  }

  public onClear(event) {
    if (this.mode === 'single') {
      this.selectedStation = [];
      this.emitSelectData();
    }
  }

  initform() {
    this.stationForm = this.formBuilder.group({
      provinceIds: new FormControl(''),
      cityIds: new FormControl(''),
      countyIds: new FormControl(''),
      propertyComps: new FormControl(''),
      stationTypes: new FormControl(''),
      name: new FormControl(''),
    });
  }

  /**
   * getRegion
   */
  public getRegion(id, type) {
    this.stationselectorservice.getRegions(id).subscribe((res: []) => {
      if (res) {
        if (type === 'province') {
          this.provinceList = [{ label: '请选择', value: null }];
          this.cityList = [];
          this.countyList = [];
          res.map((item: any) => {
            this.provinceList.push({
              label: item.regionName,
              value: item.regionId,
            });
          });
        } else if (type === 'city') {
          this.cityList = [{ label: '请选择', value: null }];
          this.countyList = [];
          this.stationForm.patchValue({
            cityIds: '',
            countyIds: '',
          });
          res.map((item: any) => {
            this.cityList.push({
              label: item.regionName,
              value: item.regionId,
            });
          });
        } else if (type === 'county') {
          this.countyList = [{ label: '请选择', value: null }];
          this.stationForm.patchValue({
            countyIds: '',
          });
          res.map((item: any) => {
            this.countyList.push({
              label: item.regionName,
              value: item.regionId,
            });
          });
        }
      }
    });
  }

  /**
   * getCompanyVal
   */
  public getCompanyVal(event) {
    this.stationForm.patchValue({
      propertyComps: event.join(','),
    });
  }

  /**
   * querySampleStations
   */
  public querySampleStations(first?) {
    if (first) {
      this.pager.page = 0;
    }
    const page = { page: this.pager.page, size: this.pager.size };

    const params = _.assign({}, this.stationForm.value, page);
    this.loading = true;
    this.stationselectorservice
      .getSampleStations(params)
      .subscribe((res: any) => {
        this.selectedStation = _.cloneDeep(this.selectedStation);
        this.stationList = res.content;
        this.stationValue = [];
        this.stationList.map((item) => {
          this.stationValue.push({
            stationId: item.stationId,
            shortName: item.shortName,
          });
        });
        this.loading = false;
        this.tableHeaderChecked = this.isAllFilteredValuesChecked(
          this.stationValue
        );
        this.pager.totalRecords = res.totalElements;
      });
  }

  /**
   * 表头全选按钮
   * tableHeaderToggle
   */
  public tableHeaderToggle() {
    let localSelection = _.cloneDeep(this.tableSelectedStations);
    if (this.tableHeaderChecked) {
      localSelection = localSelection.concat(this.stationValue);
      const hash = {};
      localSelection = localSelection.reduce((item, next) => {
        hash[next.stationId]
          ? ''
          : (hash[next.stationId] = true && item.push(next));
        return item;
      }, []);
    } else {
      localSelection = [...localSelection].filter((x) =>
        [...this.stationValue].every((y) => y.stationId !== x.stationId)
      );
    }
    this.tableSelectedStations = _.cloneDeep(localSelection);
    // console.log(this.tableHeaderChecked);
  }

  /**
   * onRowSelect
   */
  public onRowSelect(event) {
    if (this.mode === 'multiple') {
      setTimeout(() => {
        this.tableHeaderChecked = this.isAllFilteredValuesChecked(
          this.stationValue
        );
      }, 100);
    }
  }
  /**
   * onRowSelect
   */
  public onRowUnselect(event) {
    if (this.mode === 'multiple') {
      this.tableHeaderChecked = false;
    }
  }

  /**
   * 判断当前页面是不是被全部选中
   */
  public isAllFilteredValuesChecked(thisPageStations) {
    if (thisPageStations && thisPageStations.length === 0) {
      return false;
    }

    for (let index = 0; index < thisPageStations.length; index++) {
      const element = thisPageStations[index];
      if (!this.dataTable.isSelected(element)) {
        return false;
      }
    }
    return true;
  }

  /**
   * 分页
   * @param event
   */
  public onPageChange(event) {
    this.pager.page = event.page;
    this.pager.size = parseFloat(event.rows);
    this.querySampleStations();
  }

  /**
   * save
   */
  public save() {
    if (this.mode === 'single') {
      this.selectedStation = this.tableSelectedStations;
    } else if (this.mode === 'multiple') {
      this.selectedStation = this.tableSelectedStations;
    }
    this.emitSelectData();
    this.hide();
  }

  public selectPageAll() {
    this.autoCompleteFlag = false;
    this.selectedStation = this.selectedStation.concat(this.stationValue);
    const hash = {};
    this.selectedStation = this.selectedStation.reduce((item, next) => {
      hash[next.stationId]
        ? ''
        : (hash[next.stationId] = true && item.push(next));
      return item;
    }, []);
    this.emitSelectData();
    this.hide();
  }

  public selectOtherPageAll() {
    this.selectedStation = [];
    this.autoCompleteFlag = false;

    this.emitSelectData('otherParams');
    this.hide();
  }

  /**
   * clear
   */
  public clear() {
    // this.isShowDialog = false;
    this.autoCompleteFlag = true;
    this.tableHeaderChecked = false;
    this.selectedStation = [];
    this.tableSelectedStations = [];
    this.emitSelectData();
  }

  public emitSelectData(type?) {
    this.showAutoComplete(type);
    if (this.mode == 'single') {
      this.outputValueEvent.emit({
        sta_stationIds: this.selectedStation.stationId,
        sta_stationName: this.selectedStation.shortName,
      });
    } else if (this.mode == 'multiple') {
      if (type != 'otherParams') {
        this.outputValueEvent.emit({
          sta_stationIds: this.selectedStation
            .map((item) => item.stationId)
            .join(','),
        });
      } else {
        let sta_params = this.stationForm.value;
        this.outputValueEvent.emit({
          sta_provinceIds: sta_params.provinceIds,
          sta_cityIds: sta_params.cityIds,
          sta_countyIds: sta_params.countyIds,
          sta_propertyComps: sta_params.propertyComps,
          sta_stationTypes: sta_params.stationTypes,
          sta_name: sta_params.name,
        });
      }
    }
  }

  /**
   * hide
   */
  public hide() {
    this.stationForm.reset();
    this.isShowDialog = false;
    this.showEvent.emit(this.isShowDialog);
  }

  public openDialog() {
    if (this.disabled) {
      return;
    }
    this.isShowDialog = true;
    this.querySampleStations(true);
    this.tableSelectedStations = _.cloneDeep(this.selectedStation);
  }

  private showAutoComplete(type?) {
    if (this.selectedStation.length > 1 || type == 'otherParams') {
      this.autoCompleteFlag = false;
      if (type == 'otherParams') {
        this.showLabelNumber = this.pager.totalRecords;
      } else {
        this.showLabelNumber = this.selectedStation.length;
      }
    } else {
      this.autoCompleteFlag = true;
    }
  }
}
