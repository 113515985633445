import { TipService } from '@common/services/tip.service';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Dropdown, SelectItem } from 'primeng/primeng';
import * as _ from 'lodash';
import { CommonService } from '../../../services/common.service';
import { DeviceList } from '../object-selector.model';
import { ObjectSelectorService } from '../object-selector.service';

@Component({
  selector: 'mpt-device-object',
  templateUrl: 'device-object.component.html',
  styleUrls: ['../object-selector.component.less'],
})
export class DeviceObjectComponent implements OnInit, OnChanges {
  @Input() public queryIndex: number;
  @Input() public isRadio: boolean;
  @Input() public stationId: string;
  @Input() public deviceType: string;
  @Input() public excludeDeviceTypes: string;
  @Input() public selectAllFlag: boolean;
  @Input() public selected: any[] = [];
  @Output() public isShow = new EventEmitter<boolean>();
  @Output() public submitDataEvent = new EventEmitter<DeviceList[]>();
  @Output() public selectAllFlagEvent = new EventEmitter();

  @Input() public defaultStationId: string;

  public isLoading = false;
  public isSubmitAllData = false;
  public queryForm: FormGroup;
  public stationList: SelectItem[] = []; // 电站
  public stationArea: SelectItem[] = []; // 电站区域
  public deviceModel: SelectItem[] = []; // 设备型号
  public deviceData: DeviceList[] = []; // 表格数据
  public selectedData: DeviceList[] = []; // 已选择数据
  public pager = { pageCurrent: 0, totalRecords: 0, pageSize: 10 };

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private objectSelectorService: ObjectSelectorService,
    private tipservice: TipService
  ) {}

  public ngOnInit() {
    // form初始化
    this.formInit();
    if (this.stationId) {
      this.queryForm.patchValue({
        stationId: this.stationId,
      });
      this.getStationArea({ value: this.stationId });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.deviceData = [];
    this.pager = { pageCurrent: 0, totalRecords: 0, pageSize: 10 };
    if (this.queryForm) {
      this.queryForm.patchValue({
        areaIds: '',
        modelIds: '',
        deviceName: '',
      });
    }
    if (changes.stationId && this.isShow) {
      if (changes.stationId.currentValue) {
        if (!this.queryForm) {
          this.formInit();
        }
        this.queryForm.patchValue({
          stationId: changes.stationId.currentValue,
        });
        this.getStationArea({ value: changes.stationId.currentValue });
        // this.queryList();
      }
    }
    if (changes.queryIndex) {
      if (changes.queryIndex.currentValue) {
        if (this.defaultStationId) {
          this.queryForm.patchValue({
            stationId: this.defaultStationId,
          });
          this.getStationArea({ value: this.defaultStationId });
        }
        // this.queryList();
      }
    }
    if (changes.selected) {
      if (_.isEmpty(changes.selected.currentValue)) {
        this.selectedData = [];
      }
      if (
        !_.isEmpty(changes.selected.currentValue) &&
        !_.isEmpty(this.deviceData)
      ) {
        let initSelectedData = [];
        for (let data of changes.selected.currentValue) {
          if (_.find(this.selectedData, { psrId: data.psrId }) === undefined) {
            initSelectedData = _.concat(
              initSelectedData,
              _.find(this.deviceData, (item: any) => {
                return item.psrId === data.psrId;
              })
            );
          }
        }
        this.selectedData = _.concat([], this.selectedData, initSelectedData);
      }
    }
  }

  // form初始化
  public formInit() {
    this.queryForm = this.fb.group({
      stationId: new FormControl(),
      areaIds: new FormControl(),
      modelIds: new FormControl(),
      classIds: new FormControl(),
      deviceName: new FormControl(),
    });

    // 下拉框初始化
    this.dropdownInit();
  }

  // 下拉框初始化
  public dropdownInit() {
    // 电站
    this.objectSelectorService.getSampleStationAll().subscribe((data: any) => {
      this.stationList = data.map((val) => {
        return { label: val.shortName, value: val.stationId };
      });
      if (data.length && !this.stationId && !this.defaultStationId) {
        this.queryForm.patchValue({
          stationId: this.stationList[0].value,
        });
        this.loadDeviceModel(this.stationList[0].value);

        // 表格初始化
        // this.queryList();
      }
    });
  }

  // 电站区域
  public getStationArea(event) {
    this.stationArea = [];
    this.deviceModel = [];
    let newVal = this.queryForm.value;
    newVal['areaIds'] = [];
    newVal['modelIds'] = [];
    this.queryForm.setValue(newVal);

    if (_.isEmpty(event.value)) {
      return false;
    }

    this.objectSelectorService
      .getStationArea(event.value)
      .subscribe((data: any) => {
        this.stationArea = data.map((val) => {
          return { label: val.areaName, value: val.areaId };
        });
      });
    this.loadDeviceModel(event.value);
  }

  public loadDeviceModel(stationId) {
    this.objectSelectorService
      .getDeviceModel({
        stationIds: _.concat([], stationId),
        deviceTypes: this.deviceType ? this.deviceType : null,
      })
      .subscribe((data: any) => {
        this.deviceModel = data.map((val) => {
          return { label: val.modelName, value: val.modelId };
        });
      });
  }

  // 设备型号
  public getDeviceModel(event) {
    this.deviceModel = [];
    let param = this.queryForm.value;
    if (event.value.length === 0) {
      param['modelIds'] = [];
      this.queryForm.setValue(param);
    }
    let map = {
      stationIds: [param.stationId],
      areaIds: event.value,
      deviceTypes: this.deviceType ? this.deviceType : null,
    };
    this.objectSelectorService.getDeviceModel(map).subscribe((data: any) => {
      let modelIdArr = [];
      for (let val of data) {
        this.deviceModel.push({
          label: val.modelName,
          value: val.modelId,
        });
        modelIdArr.push(val.modelId);
      }
      // 联动 设置
      if (_.isArray(param.modelIds)) {
        for (let val of param.modelIds) {
          if (_.indexOf(modelIdArr, val) < 0) {
            param['modelIds'] = _.without(param.modelIds, val);
          }
        }
      }
      this.queryForm.patchValue(param);
    });
  }

  // 获取查询参数
  public getParam() {
    let params = this.queryForm.value;
    _.forIn(params, (value, key) => {
      params[key] = value ? value : null;
    });
    return { ...params, deviceType: this.deviceType ? this.deviceType : null };
  }

  // 表格查询
  public queryList(isQuery?: boolean) {
    if (isQuery) {
      this.pager.pageCurrent = 0;
    }
    this.isLoading = true;
    const param = this.getParam();
    const params = {
      stationIds: param.stationId,
      areaIds: param.areaIds,
      deviceTypes: param.deviceType,
      classIds: _.isArray(param.classIds) ? param.classIds.join() : '',
      modelIds: param.modelIds,
      deviceName: param.deviceName,
      excludeDeviceTypes: this.excludeDeviceTypes || null,
      page: this.pager.pageCurrent,
      size: this.pager.pageSize,
    };
    this.objectSelectorService.getDeviceList(params).subscribe(
      (res: any) => {
        this.pager.totalRecords = res.totalElements;
        this.deviceData = res.content;
        setTimeout(() => {
          document.getElementById('device').click();
        }, 0);
      },
      (error) => {
        this.common.errorMessage(error);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public click() {
    // console.log('device_query');
  }

  // 分页事件
  public onPageChange(event) {
    this.pager.pageCurrent = event.page;
    this.pager.pageSize = parseFloat(event.rows);
    this.queryList();
  }
  // 提交
  public submitData() {
    // if (this.selectedData.length === 0) {
    //   this.tipservice.tip('warn', '请最少选择一条数据！');
    //   return false;
    // }

    // if (this.isRadio && this.selectedData.length === 0) {
    //   this.selectedData = {
    //     deviceName: '',
    //     deviceId: ''
    //   };
    // }

    this.selectedData = this.objectSelectorService.setSelectedDataType(
      this.selectedData,
      '02'
    );
    this.submitDataEvent.emit(this.selectedData);
    this.isShow.emit(false);
    this.selectedData = [];
  }

  // 选择全部
  public chooseAllData() {
    if (this.selectAllFlag) {
      this.isShow.emit(false);
      this.selectAllFlagEvent.emit(true);
      return;
    }
    if (this.pager.totalRecords === 0) {
      this.tipservice.tip('warn', '当前没有数据可选择！');
      return false;
    }
    this.isSubmitAllData = true;
    this.selectedData = this.deviceData;
  }

  // 提交 全部
  public submitAllData() {
    // 获取全部数据接口
    let param = this.getParam();
    this.objectSelectorService
      .getDeviceListAll(this.getParam())
      .then((res: any) => {
        this.selectedData = res.content;
        this.isSubmitAllData = false;
        this.selectedData = this.objectSelectorService.setSelectedDataType(
          this.selectedData,
          '02'
        );
        this.submitDataEvent.emit(this.selectedData);
        this.isShow.emit(false);
        this.selectedData = [];
      });
  }

  // 取消选择全部
  public onCancelAllData() {
    this.isSubmitAllData = false;
    this.selectedData = [];
  }

  // 取消
  public cancelData() {
    this.isShow.emit(false);
    this.selectedData = [];
  }

  public getClassIds(data) {
    this.queryForm.patchValue({
      classIds: data,
    });
  }
}
